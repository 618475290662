import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const labellemulatresse = () => (
  <Layout>
    <SEO title="Bananes Citronnées - La belle mulâtresse" />
    <h3 className='underline-title'>La belle mulâtresse</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Titi la mulâtresse<br />
        Fille à la couleur d'alliage<br />
        <span className="par-starting"></span>- fruit de fusion<br />
        <span className="par-starting"></span>- fleur du centre<br />
        Crocotte aux mouvements jumeaux<br />
        <span className="par-starting"></span>- élan-Cap à l'allure ralentie<br />
        <span className="par-starting"></span>- cobe-Buffon à la cabriole légère<br />
        Signe de deux mondes<br />
        <span className="par-starting"></span>- trait d'union d'éléments composants<br />
        <span className="par-starting"></span>- train-d'union des mots composés<br />
        Sirène au cheveux sud-sahariens<br />
        <span className="par-starting"></span>- envahissants<br />
        Oryx au regard de volupté<br />
        Dama aux yeux de sortilège<br />
        Fille de son père<br />
        <span className="par-starting"></span>- odorée d'une senteur aphrodisiaque<br />
        Fille de sa mère<br />
        <span className="par-starting"></span>- modelée en taille ravissante<br />
        Philtre rare de la <span className="black-bold">race-centre</span><br />
        Race future de la planète sans rance
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 15 mars 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default labellemulatresse
